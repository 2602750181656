@font-face {
font-family: '__GilroyRegular_0250f0';
src: url(/_next/static/media/7f0b0712b39e8ea1-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__GilroyRegular_Fallback_0250f0';src: local("Arial");ascent-override: 88.19%;descent-override: 23.01%;line-gap-override: 15.34%;size-adjust: 104.32%
}.__className_0250f0 {font-family: '__GilroyRegular_0250f0', '__GilroyRegular_Fallback_0250f0'
}

@font-face {
font-family: '__GilroySemiBold_f0ad05';
src: url(/_next/static/media/8966fed9854c3a91-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__GilroySemiBold_Fallback_f0ad05';src: local("Arial");ascent-override: 88.90%;descent-override: 24.17%;line-gap-override: 18.29%;size-adjust: 105.51%
}.__className_f0ad05 {font-family: '__GilroySemiBold_f0ad05', '__GilroySemiBold_Fallback_f0ad05'
}

@font-face {
font-family: '__GilroyBold_51be95';
src: url(/_next/static/media/535610d79a63e652-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__GilroyBold_Fallback_51be95';src: local("Arial");ascent-override: 89.21%;descent-override: 24.80%;line-gap-override: 19.71%;size-adjust: 106.04%
}.__className_51be95 {font-family: '__GilroyBold_51be95', '__GilroyBold_Fallback_51be95'
}

@font-face {
font-family: '__GilroyExtraBold_08b2b3';
src: url(/_next/static/media/01b272b255c39276-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__GilroyExtraBold_Fallback_08b2b3';src: local("Arial");ascent-override: 89.58%;descent-override: 25.33%;line-gap-override: 21.11%;size-adjust: 106.60%
}.__className_08b2b3 {font-family: '__GilroyExtraBold_08b2b3', '__GilroyExtraBold_Fallback_08b2b3'
}

@font-face {
font-family: '__GilroyMedium_80db6f';
src: url(/_next/static/media/98fbfdfc1a259a84-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__GilroyMedium_Fallback_80db6f';src: local("Arial");ascent-override: 88.56%;descent-override: 23.64%;line-gap-override: 16.78%;size-adjust: 104.90%
}.__className_80db6f {font-family: '__GilroyMedium_80db6f', '__GilroyMedium_Fallback_80db6f'
}

@font-face {
font-family: '__GilroyLight_530808';
src: url(/_next/static/media/8ee3a02d503e2f2f-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__GilroyLight_Fallback_530808';src: local("Arial");ascent-override: 86.57%;descent-override: 22.76%;line-gap-override: 14.51%;size-adjust: 105.47%
}.__className_530808 {font-family: '__GilroyLight_530808', '__GilroyLight_Fallback_530808'
}

/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

